<script setup>
const props = defineProps({
    type: {
        type: String,
        required: true,
    },
    text: {
        type: String,
        required: true,
    },
    textProcessing: {
        type: String,
        required: false,
        default: null,
    },
    isProcessing: {
        type: Boolean,
        default: false,
        required: false,
    },
    disabled: {
        type: Boolean,
        default: false,
        required: false,
    },
    classes: {
        type: String,
        required: false,
        default: null,
    },
    action: {
        type: String,
        required: false,
        default: 'primary',
        validator: (value) => ['primary', 'secondary', 'tertiary'].includes(value),
    },
    rounded: {
        type: String,
        required: false,
        validator: (value) => ['rounded', 'rounded-md', 'rounded-lg', 'rounded-full'].includes(value),
        default: 'rounded-md',
    }
});
</script>
<template>
    <button type="submit" :disabled="props.isProcessing || props.disabled"
        :class="[props.classes, props.rounded, props.isProcessing ? 'cursor-not-allowed opacity-70':'', 
        props.action === 'primary' ? 'text-white bg-blue-950 ring-1 ring-blue-950' : (props.action === 'secondary' ? 'text-blue-950 bg-white ring-1 ring-blue-950' : 'bg-white text-blue-950') ,'h-[34px] flex gap-2 items-center justify-center px-4 py-1.5 text-xs font-semibold shadow-md hover:shadow-xl transition ease-linear duration-300 disabled:cursor-not-allowed disabled:opacity-75 ']">
        <slot name="left-icon"></slot>
        {{ props.isProcessing ? (props.textProcessing ? props.textProcessing : props.text) : props.text }}
        <slot name="right-icon"></slot>
        <svg v-if="props.isProcessing" :class="[props.action === 'primary' ? 'text-white' : 'text-blue-950','animate-spin -ml-1 mr-3 h-5 w-5']" xmlns="http://www.w3.org/2000/svg" fill="none"
            viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4">
            </circle>
            <path class="opacity-75" fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
            </path>
        </svg>
    </button>
</template>